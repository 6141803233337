<template>
  <div class="container">
    <div class="divide"></div>
    <div class="tabs">
      <span @click="backTechnical()">技术支持与服务 > </span>
      <span>故障排除指南</span>
    </div>
    <div style="min-height: 550px">
      <div class="content">
        <div class="left">
          <div class="title">故障现象</div>
          <div
            :class="i == Index ? 'box active' : 'box'"
            v-for="(item, i) in troubleList"
            :key="i"
            @click="Index = i"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="right">
          <div class="flex">
            <div class="checkout">
              <div class="title">检查内容</div>
              <div
                class="contents"
                v-for="(item, it) in troubleList[Index].table"
                :key="it"
              >
                {{ item.content }}
              </div>
            </div>
            <div class="exclude">
              <div class="title">故障排除</div>
              <div
                class="contents"
                v-for="(item, it) in troubleList[Index].table"
                :key="it"
              >
                {{ item.exclude }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      Index: 0,
      troubleList: [
        {
          name: "电源故障",
          id: 1,
          table: [
            { content: "外接电源是否接好/电压是否正常", exclude: "重新接好" },
            { content: "电源进线/连线接触是否良好", exclude: "重新接好" },
            {
              content: "电源保险接触是否良好/是否烧毁损坏",
              exclude: "重新安装 /更换熔芯",
            },
            { content: "电源进线/连线接触是否良好", exclude: "重新连接" },
            {
              content: "电源保险接触是否良好/是否烧毁损坏备份",
              exclude: "重新接好",
            },
            {
              content: "电源进线/连线接触是否良好",
              exclude: "重新连接",
            },
           
          ],
        },
        {
          name: "过热故障",
          id: 2,
          table: [
            { content: "电路板是否损坏", exclude: "安装风扇或散热器" },
            { content: "电子元件是否损坏", exclude: "安装风扇或散热器" },
            { content: "内部的空气是否流通", exclude: "清理灰尘和杂物" },
          ],
        },
        {
          name: "电路板故障",
          id: 3,
          table: [
            { content: "电路板老化", exclude: "更换电路板" },
            { content: "电路板损坏", exclude: "更换电路板" },
            { content: "电路板断路", exclude: "断电，检查短路原因重新修复" },
          ],
        },
        {
          name: "电缆故障",
          id: 4,
          table: [
            {
              content: "电缆老化",
              exclude: "更换电缆",
            },
            {
              content: "电缆损坏",
              exclude: "更换电缆",
            },
            {
              content: "电缆连接不良",
              exclude: "重新连接电缆",
            },
          ],
        },
        {
          name: "控制器故障",
          id: 5,
          table: [
            { content: "控制器程序错误", exclude: "检查控制器程序是否正确" },
            { content: "控制器硬件故障", exclude: "检查控制器硬件是否正常" },
            { content: "控制器连接不良", exclude: "检查控制器连接是否良好" },
          ],
        },
        
      ],
    };
  },
  methods: {
    // 返回技术支持与服务页面
    backTechnical() {
      // this.$store.commit('changeTheme',false);
      this.$router.push("/support");
    },
  },
};
</script>
<style lang="scss" scoped>
.container .divide {
  // height: 1.2rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}
/* // 导航条 */
.tabs {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-top: 2px solid rgba(219, 219, 219, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 42px;
  font-size: 0.2rem;
  z-index: 99;
  margin-top: 1.1rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #565656;
    cursor: pointer;
  }
}
// 主要内容
.content {
  padding: 0.3rem 2rem;
//   display: grid;
//   grid-template-columns: 1fr 2fr; /* 两列，第一列占1份，第二列占2份 */
  display: flex;
  .title {
    font-size: 0.3rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #272727;
    cursor: pointer;
    line-height: 50px;
  }
  .left {
    width: 3rem;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(203, 203, 203, 0.5);
    padding: 0.2rem;
    box-sizing: border-box;
    margin-right: 18px;
    .box {
      font-size: 0.25rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909090;
      line-height: 0.5rem;
      cursor: pointer;
      padding-left: 0.5rem;
      box-sizing: border-box;
    }
    .active, .box:hover {
      color: #ffffff;
      background: #127bf2;
    //   border-radius: 4px;
    }
  }
  .right {
    width: 12rem;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(203, 203, 203, 0.5);
    .flex {
      display: flex;
      padding: 0.2rem;
      box-sizing: border-box;
      .checkout {
        width: 5.7rem;
        text-align: center;
        border-right: 2px solid #ebebeb;
      }
      .exclude {
        width: 5.7rem;
        text-align: center;
      }
      .contents {
        border-top: 2px solid #ebebeb;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #272727;
        line-height: 0.6rem;
        cursor: pointer;
      }
    }
  }
}
</style>